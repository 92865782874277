@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@300,100&family=Red+Hat+Display:wght@100;200&family=Roboto:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;700&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.footer1 {
  background-color: black;
  color: white;
  padding: 20px;
}

.footer1 h5 {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

}

.footer1 a {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
}

.end-product {}

li {
  list-style: none;
}

.test-review {
  font-family: 'Jost';
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  text-align: justify;
}

#navbar-font {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

}

.user-font {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 6px;
}

.ban-4 {
  background: #EDEDED;
}

.ban-tittle1 {
  font-family: 'Red Hat Display';
  font-size: 34px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  width: 300px
}

.navbar-input {
  background-color: rgba(128, 128, 128, 0);

  border-bottom: 2px solid black;
  outline: none;
  color: black;
}

a {
  text-decoration: none;
}

.test-card {
  background-color: #F5F5F5;

}

.line-dum {
  width: 100px;
  height: 4px;
  border-radius: 5px;
  background-color: #000000;
}

.img-sec img {
  border-radius: 100% !important;
  height: 80px !important;
  width: 80px !important;
  object-fit: cover !important;
}

.mySwiper {
  height: fit-content;
}

.mySwiper .swiper-pagination-bullet {

  background-color: #071C1F;
  ;
  padding: 5px;
}

/* Optionally, you can customize the active bullet color */
.mySwiper .swiper-pagination-bullet-active {
  background-color: #000000;

}

.bg-con {
  background: #F5F5F5;

}

.sub-head-1 {
  font-family: 'Red Hat Display';
  font-size: 65px;
  font-weight: 700;
  line-height: 73px;
  letter-spacing: 0em;
  text-align: left;
  width: 410px;
  height: 73px;
  top: 201px;
  left: 306px;

}

.read-more {
  width: 135px !important;
  height: 50px !important;
  top: 6345.73px !important;
  left: 1231px !important;
  border-radius: 4px;
  border: 2px solid #E5E5E5;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;


}

.end-product .text-card {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;




}

#text-head {
  font-family: 'Roboto';
  font-weight: 700;


}

.sub-text-1 {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  position: absolute;
  bottom: 150px;
}


.sub-btn {
  background-color: #1A1A1A !important;
  color: white !important;
  display: flex !important;
  border-radius: 3.08px !important;
  font-family: 'Red Hat Display' !important;
  padding: 12px 20px !important;
  font-size: 16px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 12px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  width: 154px !important;
  height: 38.54px !important;
}

.sub-btn2 {
  background-color: #1A1A1A !important;
  color: white !important;
  display: flex !important;
  border-radius: 3.08px !important;
  font-family: 'Red Hat Display' !important;
  padding: 12px 20px !important;
  font-size: 16px;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 12px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  width: 154px !important;
  height: 38.54px !important;
  position: absolute !important;
  bottom: 20px !important;
}

.sub-head-2 {
  font-family: 'Red Hat Display';
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  height: 31px;
  top: 185px;
  left: 1265px;


}

.big-con {
  height: 100%;
}

.min-con {
  height: 100%;
}

.big-con2 {
  height: 100%;
  background-color: #EDEDED;
  /* background-image: url("././Homepage/Images/banner-img-4-666bc03cb40a8.webp") ; */
  background-image: url("././Homepage/Images/aathirai-brass-3-666bc036580d0.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.min-con1 {
  height: 50%;
  background-color: #EDEDED;
  /* background-image: url("./Homepage/Images/ban-img-3.png") ; */
  background-image: url("https://assetsvilva.blr1.cdn.digitaloceanspaces.com/aathiraicraftz/web/brass%20vilakku%20replace%20(1).webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.min-con2 {
  height: 50%;
  background-color: #EDEDED;
  background-image: url("././Homepage/Images/banner-img-3-666bc03a4fd27.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.min-con3 {
  background-color: #EDEDED;
  background-image: url("././Homepage/Images/banner-img-1-666bc037c040c.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.min-con4 {
  background-color: #EDEDED;
  /* background-image: url("./Homepage/Images/banner-img-4-666bc03cb40a8.webp") ; */
  background-image: url("https://assetsvilva.blr1.cdn.digitaloceanspaces.com/aathiraicraftz/homepage-images/newlamp.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.set {
  height: 100px;
}

.sub-text1 {
  font-family: 'Red Hat Display';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  left: 1265px;
  color: #222222c4;
  font-weight: 400px;
  padding-top: 30px;
  width: 70%;
}

.sub-line {
  font-family: 'Red Hat Display';
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  width: 59px;
  height: 19px;

  left: 55.36px;


}

.font-1 {
  font-family: 'Roboto';
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  width: 257.04px;
  height: 33px;
}

.font-2 {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

}

.font-3 {
  font-family: 'Red Hat Display';
  font-size: 34px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 10px;

}

.font-4 {
  font-family: 'Red Hat Display';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  width: 251.31px;
  height: 21px;


}

.font-5 {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;

}

.shosw {
  box-shadow: none;
  border: none;
}

.off-ban {
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  height: 23px;
  background: #e51f4b;
  padding: 0px 10px;
  border-radius: 4px;
  position: absolute;
  color: white;
  margin: 10px;


}

.sub-text22 {
  font-family: 'Red Hat Display';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  width: 65%;
}

.card-title {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

}

.card-text {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

}

.con-1 {
  position: relative;
}

.read-btn {
  border: 1px solid #E5E5E5;
  font-size: 13px;
  width: 135px;
  height: 50px;
  top: 6345.73px;
  left: 239px;
  border-radius: 4px;
  text-align: center;

}

.input-group2 input {
  background-color: black;
  color: white;
  border-radius: 5px 0px 0px 5px;
}

.input-group2 input::placeholder {
  color: #999999;
}

.input-group2 button {
  background: #e51f4b;
  color: white;
  border-radius: 0px 5px 5px 0px;

}

.footer1 ul li {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #999999;

}

.mySwiper2 {
  height: fit-content;

}

.shop-title {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;

}

.footer-text {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.input-text {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #999999;


}

.input-con h5 {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

}

/* Blog css start */
.blogban1 {
  height: 230px;
  background-image: url('./Homepage/Images/blog-img-1.png');
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
}

.blogban2 {
  height: 200px;
  /* width: 320px !important; */
  background-image: url('./Homepage/Images/blog-img-2.png');
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
}

.blogcon1 p:first-of-type {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #6941C6;
  padding-bottom: 10px;
}

.blogcon1 h2 {
  padding-bottom: 10px;
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}

.blogcon1-content {
  color: #667085;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blogcon1-sub {
  display: flex;
}

.blogcon1-sub p:nth-child(1) {
  margin-right: 10px;
  width: fit-content;
  padding: 0px 10px;
  height: 20px;
  gap: 0px;
  border-radius: 10px;
  opacity: 0px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #6941C6;
  background-color: #6941c61e;
}

.blogcon1-sub p:nth-child(2) {
  margin-right: 10px;
  width: fit-content;
  height: 20px;
  gap: 0px;
  padding: 0px 10px;
  border-radius: 10px;
  opacity: 0px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #3538CD;
  background-color: #3538cd17;
}

.blogcon1-sub p:nth-child(3) {
  width: fit-content;
  padding: 0px 10px;
  height: 20px;
  gap: 0px;
  border-radius: 10px;
  opacity: 0px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #C11574;
  background-color: #c1157413;
}

.blogcon2 p:first-of-type {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #6941C6;
  padding-bottom: 20px;
}

.blogcon2 h2 {
  padding-bottom: 10px;
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  padding-bottom: 20px;
}

.blogcon2 .blogcon1-content {
  color: #667085;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blogcon2-sub {
  display: flex;
}

.blogcon2-sub p:nth-child(1) {
  margin-right: 10px;
  width: fit-content;
  padding: 0px 10px;
  height: 20px;
  gap: 0px;
  border-radius: 10px;
  opacity: 0px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #026AA2;
  background-color: #026aa222;
}

.blogcon2-sub p:nth-child(2) {
  width: fit-content;
  padding: 0px 10px;
  margin-right: 10px;
  height: 20px;
  gap: 0px;
  border-radius: 10px;
  opacity: 0px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #C11574;
  background-color: #c1157413;
}

.blogcon2-sub p:nth-child(3) {
  width: fit-content;
  padding: 0px 10px;
  height: 20px;
  gap: 0px;
  border-radius: 10px;
  opacity: 0px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #15c146;
  background-color: #15c1462e;
}

.thiruvallu {
  height: 250px;
  background-image: url('./Homepage/Images/thiruvallu.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.nextpage {
  display: flex;
  justify-content: space-between;
}

.nextpage1 a:nth-child(1) {
  border-radius: 10px;
  background-color: #C11574;
  color: white;
  padding: 10px 15px;
  margin-right: 10px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.nextpage1 a {
  border-radius: 10px;
  color: rgb(0, 0, 0);
  padding: 10px 15px;
  margin-right: 10px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.blogheadings {
  display: block;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #C92B52;
}


/* Blog Detail View Css START  */
.blogdetail h2 {
  font-family: 'Inter';
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
  text-align: left;
  padding-bottom: 20px;
}

.blogdetail span {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #6941C6;
}

.blogdetail img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  /* Ensures the image covers the entire container without stretching */
  border-radius: 20px;
  margin-top: 30px;
}

.blogdetail p {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: #3B3C4A;
  margin-top: 40px;
}

.blogaddbanner {
  background-color: #E8E8EA;
  border-radius: 10px;
  border-left: 4px solid #5b5b5c72;
}

.blogaddbanner p {
  padding: 30px;
  font-family: 'Source Serif Pro', serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: black;
}

.blogimage {
  object-fit: cover;
  /* Ensures the image covers the entire container without stretching */

}

.blogbtn {
  width: 160px;
  height: 50px;
  top: 3089px;
  left: 880px;
  border-radius: 4px;
  background: #1A1A1A;
  color: white;
  margin: 30px 0px;
}

/* Blog Detail View Css END  */

.ganeshimg img {
  width: 250px;
}

.pointer {
  cursor: pointer !important;
}

/* Media query for screens up to 600px width */
@media screen and (max-width: 700px) {
  .ganeshimg img {
    width: 350px !important;
  }

  .blogdetail p {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #3B3C4A;
    margin-top: 30px;
  }

  .blogdetail h2 {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
    padding-bottom: 20px;
  }

  .blogcon1-sub {
    display: flex;
    justify-content: center;
  }

  .blogaddbanner p {
    padding: 30px;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: black;
  }

  .nextpage1 a:nth-child(1) {
    border-radius: 10px;
    background-color: #C11574;
    color: white;
    border-radius: 10px;
    padding: 10px 15px;
    margin-right: 10px;
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }

  .nextpage1 a {
    border-radius: 10px;
    color: rgb(0, 0, 0);
    padding: 5px 10px;
    margin-right: 10px;
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }

  .nextpage {
    display: block;
    text-align: center;

  }

  .blogban1 {
    height: 130px;
    width: 100%;
    background-image: url('./Homepage/Images/blog-img-1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: cover;
  }

  .sub-text22 {
    font-family: 'Red Hat Display';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    position: absolute;
    bottom: 5px;
    position: relative;
    margin-bottom: 10px;
    padding-top: 10px;
    width: 60%;

  }

  .font-1 {
    font-size: 20px;
  }

  .shop-title {
    font-size: 16px;

  }

  .input-con h5 {
    display: flex;
    justify-content: center;
  }

  .input-con .sec-con {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ban-imgs img {
    width: 200px
  }

  .sub-head-1 {
    font-family: 'Red Hat Display';
    font-size: 21px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    width: 180px;


  }

  .navbar-input {
    display: none;
  }

  .sub-head {
    font-size: 41px;
    line-height: 45px;
  }


  .sub-btn {
    background-color: #1A1A1A !important;
    color: white !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: absolute !important;
    bottom: 0 !important;
    margin-bottom: 10px !important;
    border-radius: 3px !important;
    font-family: 'Red Hat Display' !important;
    padding: 1px 1px !important;
    font-size: 8px !important;
    font-weight: 700 !important;
    line-height: 8px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    height: 25px !important;
    width: 90px !important;
  }

  .sub-btn2 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute !important;
    bottom: 0 !important;
    margin-bottom: 10px !important;
    border-radius: 3px !important;
    font-family: 'Red Hat Display';
    padding: 1px 1px !important;
    font-size: 8px !important;
    font-weight: 700 !important;
    line-height: 8px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    height: 25px !important;
    width: 90px !important;
  }

  .sub-text-1 {
    font-family: 'Roboto';
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    position: absolute;
    bottom: 20px;
  }

  .font-3 {
    font-family: 'Red Hat Display';
    font-size: 16px;
    position: relative;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 1px;
    width: 200px;

  }

  .card-title {
    font-size: 13px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;

  }

  .inputshow {
    width: 100%;
  }

  .font-4 {
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    width: 70%;

  }

  .sub-text1 {
    font-family: 'Red Hat Display';
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    left: 1265px;
    color: #222222c4;
    font-weight: 400px;
    padding-top: 30px;
    width: 70%;
  }

  .ban-tittle1 {
    font-family: 'Red Hat Display';
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    width: 200px;

  }

  .min-con1 {
    height: 70% !important;
  }

  .min-con2 {
    height: 70%;
  }

  .min-con3 {
    height: 250px;
  }

  .min-con4 {
    height: 250px;
  }

  .bestseller {
    margin-top: 200px;
  }
}

@media screen and (max-width: 1400px) {
  .sub-head {
    font-size: 41px;
    line-height: 45px;
  }

  .sub-text-1 {

    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    position: absolute;
    bottom: 140px;

  }

  .sub-head-2 {
    font-family: 'Red Hat Display';
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    top: 185px;
    left: 1265px;


  }


}


/* blog */



.recentblog {
  border: 0.8px solid #CCC;
  /* display: flex;
  justify-content: center; */

}

.recentblog h3 {
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 100% */
  border-bottom: 0.8px solid #CCC;
  padding: 15px 0px;
  text-align: center;
}

.recentblog_content {
  padding: 20px;
}

.recentblog_content img {
  object-fit: contain;
  margin: 0 auto;
}

.recentblog_content p {
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 128.571% */
  padding-top: 15px;
}

.recentblog_content span {
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 100% */
  opacity: 0.6;
}

.show-more-btn {
  margin-right: 10px;
  width: 100%;
  padding: 0px 10px;
  height: 30px;
  gap: 0px;
  border-radius: 50px;
  /* Updated to circular shape */
  opacity: 0px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #000;
  /* Updated text color */
  background: linear-gradient(180deg, #F5E06C 0%, #F1C24E 100%) !important;
  /* Updated background gradient */
  margin: 10px auto;
}

.blog_all_image img {
  height: 200px;
  width: 100%;
  object-fit: contain;
  object-position: top;
  transition: transform 0.3s ease;
  /* Smooth transition */
}

.blog_all_image img:hover {
  transform: scale(1.1);
  /* Zoom the image slightly */
}

.blog_all_content h2 {
  margin-bottom: 20px;
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 122.222% */
}

.blog_all_homepage_content h2 {
  margin-bottom: 20px;
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 122.222% */
}

.ptag_blog_all_content {
  margin-bottom: 20px;

  color: #000;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 116.667% */
}

.ptag_blog_all_content span {
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 116.667% */
  opacity: 0.6;
}

.blog_stort_content {
  margin-bottom: 20px;

  color: #000 !important;
  font-family: "Source Sans Pro" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  /* 171.429% */
  opacity: 0.9 !important;
}

.read-more-link {
  display: flex;
  height: 48px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: linear-gradient(180deg, #FADA90 0%, #F1C455 100%);
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  width: fit-content;
  transition: all 0.3s ease;
  /* Smooth transition for all changes */
}

.read-more-link:hover {
  background: linear-gradient(180deg, #F1C455 0%, #FADA90 100%);
  /* Reverse gradient on hover */
  transform: scale(1.05);
  /* Slight zoom effect */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* Add subtle shadow on hover */
}

.read-more-home_link {
  display: flex;
  height: 28px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: linear-gradient(180deg, #FADA90 0%, #F1C455 100%);
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  width: fit-content;
  transition: all 0.3s ease;
  /* Smooth transition for all changes */
}

.homepage_blog img {
  object-fit: cover;
  height: 150px;
  width: 100%;
}

.location_con p {
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.663px;
  margin-bottom: 25px;
  margin-top: 20px;
}

.homeblog_line {
  border: 1px solid #87878735;
  padding: 15px;
}

.read-more-home_link:hover {
  background: linear-gradient(180deg, #F1C455 0%, #FADA90 100%);
  /* Reverse gradient on hover */
  transform: scale(1.05);
  /* Slight zoom effect */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* Add subtle shadow on hover */
}

.blog_hometext {
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.663px;
  margin-bottom: 25px;
}

.blog_hometext a {
  color: rgba(0, 0, 0, 0.60);
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.663px;
  /* 147.593% */
}